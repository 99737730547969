
import { Component, Prop, Vue } from "vue-property-decorator";
import { OfferSingle } from "@/services/offers/OfferSingle";
import BaseCard from "@/components/base/BaseCard.vue";
import TableWithFrontPaginationDesign from "@/components/base/table/TableWithFrontPaginationDesign.vue";
import SelectDesign from "@/components/base/form/SelectDesign.vue";
import { ICreateOfferAdditionalUrl, IEditableOfferFields, IOfferAdditionalUrl } from "@/api/types/offers";
import { IFilter, ITableStatus } from "@/types";
import { filterClass, SelectFilter } from "@/services/filters/filterClass";
import { translateUserStatus as translateLinkStatus } from "@/utils/translate";
import { ITableHeader, showServerError } from "@/utils";
import { createOfferAdditionalUrls, updateOfferAdditionalUrls } from "@/api/offers";
import FormModal from "@/components/base/FormModal.vue";
import TrashBtn from "@/components/base/buttons/TrashBtn.vue";
import { CREATE_OFFER_VALIDATE_RULES } from "@/services/offers/CreateOffer";
import OfferLinksHint from "@/components/offers/offer/OfferLinksHint.vue";
import SvgPlus from "@/assets/icons/plus.svg";
import EditIconBtn from "@/components/base/buttons/EditIconBtn.vue";
import CancelBtn from "@/components/base/buttons/CancelBtn.vue";
import { useOfferCardStore } from "@/store_v3/offerCard";

@Component({
    components: {
        OfferLinksHint,
        TrashBtn,
        FormModal,
        SelectDesign,
        TableWithFrontPaginationDesign,
        BaseCard,
        SvgPlus,
        EditIconBtn,
        CancelBtn,
    },
})

export default class OfferLinksTable extends Vue {
    @Prop({ required: true }) offer!: OfferSingle;

    isEdit: boolean = false;
    isLoading: boolean = false;
    editedItems: IOfferAdditionalUrl[] = [];
    isShowModal: boolean = false;
    store = useOfferCardStore();

    status: boolean | string = 'active';
    filterStatus: IFilter = new filterClass.Select({
        id: 'status',
        label: 'Статус',
        itemValue: 'value',
        itemText: 'name',
        items: ['active', 'deleted'],
        select: this.status,
        callback: this.changeStatus,
        clearable: true,
        remove: this.deleteStatus,
        translate: (status: string) => translateLinkStatus(status).text,
    });

    headers: ITableHeader[] = [
        { text: 'ID', sortable: false, value: 'legacy_id', width: '50px' },
        { text: 'Название', sortable: false, value: 'name', width: '220px' },
        { text: 'Preview URL', sortable: false, value: 'preview' },
        { text: 'Offer URL', sortable: false, value: 'target' },
        { text: 'Приватность', sortable: false, value: 'is_private', align: 'center', width: '50px' },
        { text: 'Статус', sortable: false, value: 'status', align: 'center', width: '50px' },
    ];

    statuses: ITableStatus[] = [
        { slot: 'item.status', key: 'status', translate: translateLinkStatus },
    ];

    textData = [
        { slot: 'item.name', key: 'name' },
        { slot: 'item.preview', key: 'preview', isRoute: true },
        { slot: 'item.target', key: 'target', isRoute: true },
    ];

    uniqueData = [
        { slot: 'item.name' },
        { slot: 'item.preview' },
        { slot: 'item.target' },
        { slot: 'item.is_private', template: this.translatePrivate },
    ];

    createLinkForm = {
        name: { name: 'Название', value: '' },
        preview: { name: 'Preview URL', value: '' },
        target: { name: 'Offer URL', value: '' },
    };

    get isDisableSaveLink(): boolean {
        const isEmptyField = Object.values(this.createLinkForm).some(i => !i.value);
        const isValidUrl = CREATE_OFFER_VALIDATE_RULES.URL(this.createLinkForm.target.value, this.offer.traffics[0]?.id, String(this.offer.tracking_protocol?.type));
        return isEmptyField || isValidUrl !== true;
    }

    get isEditDisabled(): boolean {
        return this.getItems.some(i => {
            if (i.id) {
                return i.target.includes('{transaction_id}') ? '' : 'Offer URL должен содержать transaction_id';
            } else {
                const isValid = CREATE_OFFER_VALIDATE_RULES.URL(i.target, this.offer.traffics[0]?.id, String(this.offer.tracking_protocol?.type));
                return isValid !== true;
            }
        });
    }
    get validateOfferLink(): any {
        const trafficType = this.offer.traffics[0]?.id;
        const tracking = this.offer.tracking_protocol?.type;
        const target = this.createLinkForm.target.value;
        if (trafficType === 2 && tracking === 'postback') {
            return target.includes('{transaction_id}') ? '' : 'Offer URL должен содержать transaction_id';
        } else return '';
    }

    get defaultUrlOffer(): IOfferAdditionalUrl {
        return {
            name: 'URL оффера по-умолчанию',
            preview: this.offer.preview_url,
            target: this.offer.default_url,
            is_private: 0,
            status: 'active',
        };
    }

    get getItems(): IOfferAdditionalUrl[] {
        const urls = this.isEdit ? this.editedItems : [this.defaultUrlOffer, ...this.offer.additional_urls!];
        return  this.status ? urls?.filter(i => i.status! === this.status)! : urls;
    }

    getPrivateSelectOptions(item: IOfferAdditionalUrl): IFilter {
        return new filterClass.Select({
            id: 'is_private',
            label: '',
            itemValue: 'value',
            itemText: 'name',
            items: [0, 1],
            select: item.is_private,
            clearable: false,
            callback: this.changePrivate,
            translate: this.translatePrivate,
            disabled: !item.id,
        });
    }

    getStatusSelectOptions(item: IOfferAdditionalUrl): IFilter {
        return new filterClass.Select({
            id: 'active',
            label: '',
            itemValue: 'value',
            itemText: 'name',
            items: ['active', 'deleted'],
            select: item.status,
            clearable: false,
            callback: this.changeLinkStatus,
            translate: (status: string) => translateLinkStatus(status).text,
            disabled: !item.id,
        });
    }


    translatePrivate(item: IOfferAdditionalUrl | number): string {
        const value = typeof item  === 'number' ? item : item.is_private;
        return !!value ? 'Да' : 'Нет';
    }

    changePrivate(filter: SelectFilter, item: IOfferAdditionalUrl): void {
        item.is_private = filter.select;
    }

    changeLinkStatus(filter: SelectFilter, item: IOfferAdditionalUrl): void {
        item.status = filter.select;
    }

    changeStatus(e: IFilter):void {
        this.status = e.select;
    }

    deleteStatus(): void {
        this.status = '';
    }

    editLinks(isEdit: boolean): void {
        if (isEdit) {
            this.editedItems = JSON.parse(JSON.stringify(this.getItems));
        } else {
            this.saveChanges();
        }
        this.isEdit = isEdit;
    }

    cancelEdit(): void {
        this.editedItems = [];
        this.isEdit = false;
    }

    async saveChanges(): Promise<void> {
        const prepareData = (item: IOfferAdditionalUrl) => {
            const oldItem = this.offer.additional_urls?.find(i => i.legacy_id === item.legacy_id);
            for (const key in oldItem) {
                if (item[key] === oldItem[key]) delete item[key];
            }
            return { id: oldItem?.id, data: item };
        };

        try {
            this.isLoading = true;
            let isOfferUrlsUpdated = false;
            if (!this.editedItems[0].id) {
                const offerUrls = this.editedItems.splice(0, 1);
                isOfferUrlsUpdated = await this.saveOfferUrls(offerUrls[0]);
            }
            const dataCollection = this.editedItems.map(i => prepareData(i)).filter(i => Object.keys(i.data).length > 0);
            await Promise.all(dataCollection.map(({ id, data }) => updateOfferAdditionalUrls(id!, data)));
            if (isOfferUrlsUpdated || dataCollection.length > 0) {
                this.store.setIsUpdateOffer(true);
            }
        } catch (err) {
            showServerError(err, 'Ссылки не обновлены');
        } finally {
            this.isLoading = false;
        }
    }

    async saveOfferUrls(offerUrls: IOfferAdditionalUrl): Promise<boolean> {
        const data: IEditableOfferFields = {
            default_url: offerUrls.target,
            preview_url: offerUrls.preview,
        } as IEditableOfferFields;
        return await this.offer.editOffer(data);
    }

    closeModal(): void {
        this.isShowModal = false;
        for (const key in this.createLinkForm) {
            this.createLinkForm[key].value = '';
        }
    }

    async createLink(): Promise<void> {
        try {
            this.isLoading = true;
            const { name, preview, target } = this.createLinkForm;
            const data: ICreateOfferAdditionalUrl = {
                offer_id: this.offer.legacy_id!,
                name: name.value,
                preview: preview.value,
                target: target.value,
                private: !!this.offer.private,
            };
            await createOfferAdditionalUrls(data);
            this.store.setIsUpdateOffer(true);
        } catch (err) {
            showServerError(err, 'Ссылка не создана');
        } finally {
            this.isLoading = false;
            this.closeModal();
        }
    }
}

